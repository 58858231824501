import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"

const prefix = '/api/ad'
const prefix2 = '/api/dscloud-appservice-management'
// 咨询列表
export function consultingManagementList(data) {
    return fetchApi(URL.CONSULTING_LIST, data, "post", "data",true)
}

// 咨询添加
export function consultingAdd(data) {
    return fetchApi(URL.CONSULTING_ADD, data, "post", "data")
}

// 咨询删除
export function consultingDelete(data) {
    return fetchApi(URL.CONSULTING_DELETE + "/" + data.id, {}, "delete")
}

// 咨询发布or撤回
export function consultingPublish(data) {
    return fetchApi(URL.CONSULTING_PUBLISH + "/" + data.id + "/" + data.status, {}, "put")
}

// 咨询详情
export function consultingDetail(data) {
    return fetchApi(URL.CONSULTING_DETAIL + "/" + data.id, {}, "get",'params',true)
}

// 咨询编辑
export function consultingEdit(data) {
    return fetchApi(URL.CONSULTING_EDIT, data, "put", "data")
}

// 获取当前用户拥有的资讯类型
export const selectTypeListByUserAuth = () => {
    return fetchApi(prefix + '/web/consult/type/downBox')
}

// 根据资讯类型获取关联的项目
export const getProjectListByTypeId = (id) => {
    const params = {
        consultTypeId: id
    }
    return fetchApi(prefix + '/web/consult/type/project/downBox', params, 'get', 'params',true)
}

// 获取项目
export function getProject() {
    return fetchApi(URL.GET_PROJECT, null, 'get')
}

// 添加资讯类型
export const addType = (data) => {
    return fetchApi(prefix + '/web/consult/type/add', data, 'post', 'data')
}

// 获取资讯类型list
export const getConsultingTypeList = (data) => {
    return fetchApi(prefix + '/web/consult/type/list', data, 'POST', 'data')
}

// 根据id查询类型详情
export const getTypeItemById = (id) => {
    return fetchApi(prefix + `/web/consult/type/${id}`)
}

// 编辑资讯类型
export const editType = (data) => {
    return fetchApi(prefix + '/web/consult/type/update', data, 'post', 'data')
}

// 删除资讯类型
export const deleteTypeById = (id) => {
    return fetchApi(prefix + `/web/consult/type/${id}`, {}, 'DELETE', 'params')
}
// 顺义获取项目接口
export function getShunyiAllProject(data) {
    return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST, data, 'post', 'data',true);
  }
export function getModuleList(data) {
    return fetchApi(prefix2+ '/web/module/findBindingModule', data, 'post', 'data',true);
  }